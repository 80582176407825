import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a href="/radca/nadcha/zabojujte_s_nadchou_rychlo/" className="sel">
              {" "}
              &gt; Zabojujte s nádchou rýchlo
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_152527238.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Zabojujte s nádchou rýchlo</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <b>
                    Pri ceste do práce stretnete niekoľko kýchajúcich ľudí,
                    kolegyňa pri stole sa nevie odtrhnúť od balíčka vreckoviek a
                    spolubývajúci začne pociťovať škrabanie v hrdle, oslabenie a
                    podráždenie. Nemáte šancu! Kontakt s vírusmi spôsobí, že
                    zápal nosovej sliznice po dvoch dňoch dostihne aj vás. Ako
                    zmierniť príznaky nádchy? Čím ich liečiť? Existuje viacero
                    osvedčených metód, ktoré sa oplatí použiť.{" "}
                  </b>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Nádcha je prenosným ochorením. Vírusy sa do zdravého organizmu
                    dostanú v kvapkách tzv. kvapôčkovou{" "}
                    <a href="/slovnik_pojmov/#infekcia" target="_blank">
                      infekciou
                    </a>
                    . Stačí, že sa chorý objaví v tej istej miestnosti ako vy,
                    alebo tam bol predtým. V takom prípade začnete pociťovať
                    príznaky, ako napríklad: škrabanie v nose, celkové
                    oslabenie. Následne sa rozšíria cievky nosovej
                    sliznice, vznikne opuch nosovej sliznice a začnete mať
                    ťažkosti s dýchaním nosom. Komplikuje vám to fungovanie a
                    vykonávanie bežných činností počas dňa a negatívne
                    ovplyvňuje zaspávanie a pokojný spánok v noci. V boji s
                    vírusmi vám môžu pomôcť inhalácie rumančekového záparu,
                    horúci kúpeľ alebo čaj s medom a zázvorom. Počas prvých dní{" "}
                    <a href="/slovnik_pojmov/#infekcia" target="_blank">
                      infekcie
                    </a>{" "}
                    treba zostať doma, aby sa telo rýchlo zbavilo „narušiteľov“.
                    Nezabúdajte, že na začiatku choroby ste „prenášačmi
                    infekcie“ a rýchlo môžete niekoho nakaziť.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="OLYNTH® riešenie pre rýchle uvoľnenie nosa a prínosových dutín."
                    src="/web/kcfinder/uploads/images/shutterstock_1097978291.jpg"
                    style={{
                      height: "280px",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      "border-width": "2px",
                      "border-style": "solid",
                      width: "300px"
                    }}
                  />
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Osvedčeným domácim liekom na nádchu je rozohriatie nôh
                    horúcou vodou s kuchynskou soľou a kúpeľ s prídavkom
                    mätového alebo eukalyptového oleja. Rovnako dôležitý pre
                    posilnenie organizmu je aj odpočinok. Pred spaním si natrite
                    chodidlá gáfrovou masťou, obujte si hrubé vlnené ponožky a
                    zabaľte sa do ďalšej deky, aby ste sa v noci vypotili. Je
                    vhodné zadovážiť si lieky, ktoré pomôžu uvoľniť dýchanie
                    nosom a zmierniť príznaky nádchy. Nosová roztoková
                    aerodisperzia, napríklad{" "}
                    <a href="/lieky/olynth_ha_01/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,1 %,</a> uvoľňuje nos vďaka obsahu
                    {" "}
                    <a href="/slovnik_pojmov/#xylometazolin">xylometazolínu</a>.
                    Zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam.&nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Pri nádche nezabúdajte na správnu hygienu nosovej dutiny a
                    používanie antibakteriálneho mydla na umývanie rúk. Na
                    čistenie nosa je najlepšie používať mäkké jednorazové
                    vreckovky a na zmiernenie podráždenej kože v okolí nosa
                    hydratačné krémy. S cieľom posilniť organizmus je potrebné
                    pravidelne konzumovať ovocie a zeleninu, ktoré telu dodávajú
                    veľa vitamínov (napr. jablká, citróny, paradajky a zemiaky
                    obsahujú vysoké množstvá vitamínu C, grapefruity, melón a
                    broskyne sú bohaté na vitamín A). Zároveň treba predchádzať
                    šíreniu choroboplodných mikrooganizmov a upratovať domácnosť
                    nielen s použitím čistiacich prostriedkov, ale aj
                    prostriedkov, ktoré ničia baktérie.{" "}
                  </span>
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra:&nbsp;</span>
                  </span>
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    P. Rapiejko, A. Lipiec, Podział nieżytów nosa, [v:]
                    „Alergia”, 2013, nr 2, s. 6-8.{" "}
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg)"}} />
                  <span className="title">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg)"}} />
                  <span className="title">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/nadcha/alergicka_nadcha_spoznajte_ju_a_predchadzajte_jej/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)"}} />
                  <span className="title">
                    Alergická nádcha – spoznajte ju a predchádzajte jej
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
